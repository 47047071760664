import { ReactComponent as InfoIcon } from 'assets/img/DividendsPage/info.svg';

import { IWithdrawInfo } from './types';
import styles from './styles.module.scss';

const WithdrawInfo: React.FC<IWithdrawInfo> = ({ text }) => {
  return (
    <div className={styles.wrapper}>
      {text ? <span className={styles.tooltip}>{text}</span> : null}
      <InfoIcon className={styles.info} />
    </div>
  );
};

export default WithdrawInfo;
