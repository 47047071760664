/* eslint-disable no-useless-escape */
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReferralsCookie, ReferralsType } from 'components/constants/constants';

const ReferralLayer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  useEffect(() => {
    const cookies = new Cookies();
    const params = new URLSearchParams(search);

    const cid = params.get(ReferralsType.externalAffiliate);
    const referral = params.get(ReferralsType.referral) || getCodeByType(pathname, ReferralsType.referral);
    const affiliate = params.get(ReferralsType.affiliate) || getCodeByType(pathname, ReferralsType.affiliate);

    if (cid) {
      const expireDate = dayjs().add(7, 'days').toDate();

      cookies.set(ReferralsCookie.externalAffiliate, cid, { expires: expireDate });
    }

    if (affiliate) {
      const expireDate = dayjs().add(7, 'days').toDate();

      cookies.set(ReferralsCookie.affiliate, affiliate, { expires: expireDate });

      setParams('affiliate');
      return;
    }

    if (referral) {
      const expireDate = dayjs().add(7, 'days').toDate();

      cookies.set(ReferralsCookie.referral, referral, { expires: expireDate });

      setParams('referral');
    }
  }, []);

  function setParams(searchParam: string) {
    const params = new URLSearchParams(search);
    params.append('utm_medium', searchParam);
    params.append('utm_campaign', searchParam);

    navigate({ search: params.toString() });
  }

  const getCode = (link: string) => {
    const codeRegex = /[^|=]*$/gim;
    const matched = link.match(codeRegex);

    return matched[0] || '';
  };

  function getCodeByType(link: string, type: ReferralsType) {
    const refRegex = /ref=/gim;
    const affRegex = /aff=/gim;

    if (type === ReferralsType.referral) {
      const hasRef = refRegex.test(link);

      return hasRef ? getCode(link) : null;
    }

    if (type === ReferralsType.affiliate) {
      const hasAff = affRegex.test(link);

      return hasAff ? getCode(link) : null;
    }
  }

  return <>{children}</>;
};

export default ReferralLayer;
